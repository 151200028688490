.button-back {
  min-width: 64px !important;
  background-color: white !important;
  box-shadow: none !important;
  color: #c4cdd5 !important;
  border: 2px solid #c4cdd5 !important;
  border-radius: 35px !important;
  font-size: 14px !important;
  line-height: 1rem !important;
  width: auto !important;
  padding: 4px 16px !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  text-align: center;
  text-decoration: none;
  font-family: Inter, sans-serif;
}
.button-back:hover {
  background-color: #c4cdd5 !important;
}

.button-next {
  min-width: 64px !important;
  background-color: #f75f69 !important;
  /*box-shadow: none!important;*/
  color: white !important;
  border: 2px solid #f75f69 !important;
  border-radius: 35px !important;
  font-size: 16px !important;
  line-height: 1.11rem !important;
  width: 100%;
  max-width: 360px;
  padding: 8px 0 !important;
  font-weight: bold !important;
}
.button-next:hover {
  color: #f75f69 !important;
  background-color: white !important;
}
.button-next.Mui-disabled {
  color: #454f5b !important;
  background-color: #dfe3e8 !important;
  border-color: #dfe3e8 !important;
}

.checkbox-padding {
  align-items: start !important;
  padding-top: 0px !important;
  margin-bottom: 8px !important;
}
.checkbox-color .Mui-checked {
  color: #f75f69 !important;

}
.custom-radio .Mui-checked {
  color: #f75f69 !important;
}

.checkbox-color.MuiFormControlLabel-root {
  align-items: flex-start !important;
}

.productAnswer {
  background-color: white;
  border: 1px solid;
  border-color: #F4F4F4;
  box-shadow: 0 2px 6px 0 rgba(30,30,30,0.12);
  border-radius: 16px;
  min-height: 50px;
  padding: 5px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  color: #1E1E1E;
  display: flex;
  margin-bottom: 4px;
}
